<template>

  <div class="pb journal_page">

    <div class="journal journal_list">




        <UiBread
          :list="[
            {name: $t('journal_view_link_text'), link: '/journal'},
            {name: category},
          ]"
          position="left"
          /> 


          
        <div class="latests small_boxes" v-if="journal_list_data?.items_blog?.length">

          <div class="small_boxes">
            

            <div class="pj">
              <h1 class="ptl ui header">{{ category }} </h1>
              <div class="list">
                <JournalBox 
                  v-for="(item,ind) in journal_list_data.items_blog" 
                  :class="'jbox box_small'" 
                  :key="ind"
                  :data="item"      
                  />
              </div>
            </div>
 
                
          </div>

          <client-only>
            <InfinityScroll 
              :is-loading="is_loading"
              ref="el_infinity_scroll"/>
          </client-only>

        </div>
      </div>
 


  </div>

</template>

<script setup>

import { useIntersectionObserver } from '@vueuse/core'
import { watchArray } from '@vueuse/core'
import InfinityScroll from '~/components/InfinityScroll.vue';

const start = ref(0)
const limit = ref(20)
const is_ended = ref(false)
const {$api, $tagsUtil, $ga} = useNuxtApp()
const route = useRoute();
const el_infinity_scroll = ref(null)
const category = route.params['var']
const blogs_count = ref(0)

const loadData = async function() {     
  const response = await $api.getJournalCategoryList(
    start.value,
    limit.value,
    route.params['var']
  )

  if(response && start.value == 0){
    blogs_count.value = response.blogs_count
  }

  return response
}


const { pending: is_loading,  data: journal_list_data } = await useLazyAsyncData('journal_list_data', async () => await loadData())


useIntersectionObserver(
  el_infinity_scroll,
  ([{isIntersecting}]) => {
    if(isIntersecting){
      console.log('infinity scroll event');        
      if(!is_loading.value && !is_ended.value)
        start.value = start.value + limit.value;
    }
  },
  { distance: 10 }
)


watchArray([start], async ([new_start], added, removed) => {
  console.log('watcher');

  if(new_start == 0){
    journal_list_data.value = {items_blog:[],items_vote:[],blog_vacancy:[],items_tag:[]};
    is_ended.value = false;
  }

  is_loading.value = true;

  var dt = await loadData();

    
  if(dt.length == 0)
    is_ended.value = true;
  
  if(dt.items_blog.length){
    journal_list_data.value.items_blog = [...journal_list_data.value.items_blog, ...dt.items_blog]  
  }

  is_loading.value = false;
}, {deep: true})


//useHead(useNuxtApp().$head.getJournalsCategory(route.params['var'],blogs_count.value));

watchArray([route, journal_list_data], async ([new_route, new_journal_list_data], added, removed) => {
  
  useHead(useNuxtApp().$head.getJournalsCategory(route.params['var'],blogs_count.value));

}, {deep: true})


onBeforeUnmount(() => {
  journal_list_data.value = null;
})

</script>

<style scoped>
 

</style>


<style scoped>

.ptl{
  text-transform: capitalize;
  margin-bottom: 1rem;
  margin-top: 1rem;

  width: 100%;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
    font-size: 1.2rem;
    font-weight: normal;
}

.pj{  
  display: flex;
  flex-wrap: wrap;
  container: pj / inline-size;

}

.list{
  margin-top: 1rem;
}

.list:deep(.jbox.box_small){
  width: 100%;
}

</style>